/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { styled } from '@mui/system';
import { Button, IconButton, Typography } from '@mui/material';
import { HiDocumentDownload } from 'react-icons/hi';
import fileDownload from 'js-file-download';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import Content from '../../../Components/BasicComponents/Content';
import PageLoadingSpinner from '../../../Components/BasicComponents/PageLoadingSpinner';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import BackButton from '../../../Components/BasicComponents/BackButton';

import AnalyticsTable from './AnalyticsTable';
import { CampaignAnalytics } from '../../../Services/CampaignsService';
import ValidateEpoch from '../../../util/validateEpoch';

const ButtonRow = styled('div', {})({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '20px',
});

const StyledButton = styled(Button)(({ theme }) => ({
  height: '45px',
  width: '260px',
  backgroundColor: theme.palette.secondary.main,
  position: 'inherit',
  [theme.breakpoints.down('sm')]: {
    height: '35px',
    width: '90px',
  },
}));

const headings: string[] = [
  'Business ID',
  'Type',
  'Status',
  'Description',
  'Start Time',
  'End Time',
  'Reward Type',
  'Reward Quantity',
  'Reward Cost',
  'Reward Price',
  'ROI',
  '12 Month ROI',
  'Subsequent Purchases',
  'Messages Delivered',
  'Messages Read',
  'Rewards Claimed',
  'Rewards Redeemed',
  'Delivered vs Read',
  'Read vs Claimed',
  'Claimed vs Redeemed',
  'Opened Via Notification',
  'Message Link Clicked',
  'Clicked See More',
  'Promo Code',
  'Label',
  'Code Claims',
  'Code Redemptions',
  'Code ROI',
  'Code 12 Month ROI',
  'Code Subsequent Purchases',
];

type AnalyticsPageProps = {
  getAnalytics: () => void,
  analytics: CampaignAnalytics[],
  goBack: () => void,
};

function AnalyticsPage(props: AnalyticsPageProps) {
  const {
    getAnalytics,
    analytics,
    goBack,
  } = props;

  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);
  const [errorDialogTitle, setErrorDialogTitle] = useState<string>('');
  const [errorDialogMessage, setErrorDialogMessage] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [filter, setFilter] = useState<string>('all');

  const handleErrorDialog = (title:string, message:string) => {
    setErrorDialogTitle(title);
    setErrorDialogMessage(message);
    setOpenErrorDialog(true);
  };

  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
    setErrorDialogTitle('');
    setErrorDialogMessage('');
  };

  const downloadFile = () => {
    const filteredAnalytics = analytics.filter((campaign) => campaign.businessId.includes(searchText)
     && (filter !== 'all' ? filter === campaign.campaignType : true));
    const rows: string[][] = [headings];

    filteredAnalytics.forEach((campaignStats) => {
      let description = 'No Description Set';
      if (campaignStats.description !== null) description = campaignStats.description;

      let rewardType = 'No Reward Type Set';
      if (campaignStats.rewardType !== null) rewardType = campaignStats.rewardType;

      let rewardQuantity = 'No Reward Quantity Set';
      if (campaignStats.rewardQuantity !== null) rewardQuantity = campaignStats.rewardQuantity.toString();

      let start = 'Started Immediately';
      if (campaignStats.startEpoch !== 0) start = new Date(ValidateEpoch(campaignStats.startEpoch)).toLocaleString();

      let end = 'Running Indefinitely';
      if (campaignStats.endEpoch !== 0) end = new Date(ValidateEpoch(campaignStats.endEpoch)).toLocaleString();

      let notifications = '0';
      if (campaignStats.openedNotifications !== null) notifications = campaignStats.openedNotifications.toString();

      const subRow: string[] = [
        campaignStats.businessId ?? '',
        campaignStats.campaignType ?? '',
        campaignStats.status ?? '',
        description ?? '',
        start ?? '',
        end ?? '',
        rewardType ?? '',
        rewardQuantity ?? '',
        campaignStats.rewardCost.toString() ?? '',
        campaignStats.rewardPrice.toString() ?? '',
        campaignStats.returnOnInvestment.toString() ?? '',
        campaignStats.twelveMonthROI.toString() ?? '',
        campaignStats.subsequentPurchase.toString() ?? '',
        campaignStats.promotionRewardsDelivered.toString() ?? '',
        campaignStats.readCount.toString() ?? '',
        campaignStats.promotionClaimsCount.toString() ?? '',
        campaignStats.promotionRedemptionsCount.toString() ?? '',
        `${campaignStats.deliveredVsRead.toString()}%` ?? '',
        `${campaignStats.readVsClaimed.toString()}%` ?? '',
        `${campaignStats.claimedVsRedeemed.toString()}%` ?? '',
        notifications ?? '',
        campaignStats.linkeClickedCount.toString() ?? '',
        campaignStats.seeMoreCount.toString() ?? '',
      ];

      if (campaignStats.promoCodesArray.length > 0) {
        campaignStats.promoCodesArray.forEach((codePromo) => {
          let codeRow = Array.from(Array(subRow.length));
          const codeSubRow: string[] = [
            codePromo.codeId ?? '',
            codePromo.label ?? '',
            codePromo.promotionClaimsCount.toString() ?? '',
            codePromo.promotionRedemptionsCount.toString() ?? '',
            codePromo.returnOnInvestment ?? '',
            codePromo.twelveMonthROI ?? '',
            codePromo.subsequentPurchase.toString() ?? '',
          ];
          codeRow = [...codeRow, ...codeSubRow];
          rows.push(codeRow);
        });
      }

      rows.push(subRow);
    });
    const csv = rows.map((row) => row.map((r) => JSON.stringify(r))).join('\r\n');
    fileDownload(csv, 'campaign_analytics.csv');
  };

  return (
    <Content>
      <ButtonRow>
        <BackButton onClick={goBack} />
        <StyledButton
          aria-label="download"
          variant="contained"
          startIcon={<HiDocumentDownload />}
          onClick={() => downloadFile()}
        >
          <Typography variant="body2">Download Analytics</Typography>
        </StyledButton>
      </ButtonRow>

      {analytics.length > 1 ? (
        <AnalyticsTable
          tableItems={analytics}
          filter={filter}
          setFilter={setFilter}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      ) : (
        <PageLoadingSpinner />
      )}

      <ErrorDialog
        open={openErrorDialog}
        dialogTitle={errorDialogTitle}
        dialogText={errorDialogMessage}
        handleClose={handleErrorDialogClose}
      />

    </Content>
  );
}

export default AnalyticsPage;
